import React from "react";
import { ImageInterface } from "../../types/SanityTypes";
import SanityImage from "gatsby-plugin-sanity-image";
import Section from "../Section";
import "./styles.scss";
import { IMAGE_SIZES } from "../../constants";

interface ImpactBlockInterface {
  impacts: {
    image: ImageInterface;
    sanityImage: ImageInterface;
    title: string;
    subtitle: string;
    logo: ImageInterface;
  };
}

const ImpactBlock = ({ impacts }: { impacts: ImpactBlockInterface[] }) => {
  return (
    <Section sectionClassName="c-impact-block">
      <div className="c-impact-block__list">
        {impacts.map((impact: any, i: number) => {
          const sanityImage = {
            alt: impact?.logo?.alt,
            asset: {
              _id: impact?.logo?.asset?._id,
              metadata: {
                preview: impact?.logo?.asset?.metadata?.lqip,
                lqip: impact?.logo?.asset?.metadata?.lqip
              }
            }
          };
          return (
            <div key={i} className="grid-col-md-6 c-impact-block__impact">
              <div className="c-impact-block__impact-title-block">
                <SanityImage
                  asset={sanityImage.asset}
                  alt={sanityImage.alt}
                  height={60}
                  width={90}
                  htmlWidth={90}
                  htmlHeight={60}
                  sizes={IMAGE_SIZES.TINY_SQUARE}
                  className="c-impact-block__logo smb-xs-24 smb-md-36"
                />
                {impact.image.caption && (
                  <caption className="c-impact-block__image-caption">{impact.image.caption}</caption>
                )}
                <h2 className="c-impact-block__title">{impact.title}</h2>
                <p className="c-impact-block__subtitle">{impact.subtitle}</p>
              </div>
              <div className="c-impact-block__inner-list">
                {impact.sanityImage.map((image: ImageInterface, i: number) => {
                  return (
                    <div className="grid-col-6 c-impact-block__impact-image" key={i}>
                      <SanityImage
                        className="c-impact-block__image c-impact-block__image--responsive c-impact-block__image--rounded smb-xs-24 smb-md-36"
                        {...image}
                        sizes={IMAGE_SIZES.CARD}
                        width={310}
                        height={175}
                        htmlWidth={310}
                        htmlHeight={175}
                      />
                      {image.caption && <div className="c-impact-block__image-caption">{image.caption}</div>}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </Section>
  );
};

export default ImpactBlock;
